.StatusBox {
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  background: linear-gradient(#e8e8e8 0%, #eee 100%);
  padding: 24px 32px;
  overflow-x: auto;
}

.StatusBox::-webkit-scrollbar {
  display: none;
}

.CustomerStep {
  min-width: 100px;
  text-align: center;
  vertical-align: top;
  display: inline-block;
}

.CustomerStep .circle {
  width: 80px;
  height: 80px;
  text-align: center;
  background: #f8f8f8;
  border-radius: 50%;
  display: inline-block;
}

.CustomerStep .icon {
  width: 40px;
  margin-top: 20px;
}

.CustomerStep .label {
  color: #222;
  margin-top: 8px;
  font-size: 14px;
}

.CustomerStep .todo {
  color: #fff;
  width: auto;
  background: linear-gradient(#f59239 0%, #ff7b0d 100%);
  border-radius: 8px;
  margin-top: 8px;
  padding: 8px 12px;
}

.arrow {
  width: 0;
  height: 0;
  border-top: 20px solid #0000;
  border-bottom: 20px solid #0000;
  border-left: 20px solid #f8f8f8;
  margin: 24px;
  display: inline-block;
}

@media screen and (max-width: 700px) {
  .arrow {
    width: 0;
    height: 0;
    border-top: 16px solid #0000;
    border-bottom: 16px solid #0000;
    border-left: 16px solid #f8f8f8;
    margin: 12px;
    display: inline-block;
  }

  .StatusBox {
    padding: 10px 8px 16px;
  }

  .CustomerStep .circle {
    width: 60px;
    height: 60px;
  }

  .CustomerStep .icon {
    width: 44px;
    margin-top: 8px;
    margin-left: 2px;
  }

  .CustomerStep .label {
    margin-top: 4px;
    font-size: 12px;
  }

  .CustomerStep .todo {
    margin-top: 4px;
    padding: 6px 14px;
    font-size: 14px;
  }
}

/*# sourceMappingURL=index.9f2cfb88.css.map */
